import React, { useEffect, useState } from 'react';
import './index.scss';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { catch_error, open_door } from 'redux/actions/main';
import { toggleModal } from 'redux/actions/modal';

import { Button } from 'components';

import { audio } from 'helper/audio';

type DoorProps = {
  number: number;
};

const Doors: React.FC = () => {
  const Door = ({ number }: DoorProps): JSX.Element => {
    const toggleCheckBox: () => void = () => {
      if (on_open_animation || on_breach_animation) return;
      if (checkedDoors.length === 2 && !isChecked) return;

      audio(assets.sound_click).play();

      let newArray: number[] = [];

      if (isChecked) {
        newArray = checkedDoors.filter((data) => data !== number);
      } else {
        newArray = [...checkedDoors];
        newArray.push(number);
      }

      setCheckedDoor(newArray);
    };

    const isChecked: boolean = checkedDoors.includes(number);
    const prize = temp_prizes[number];

    return (
      <div className={`door ${isChecked ? 'on' : ''}`} onClick={toggleCheckBox}>
        <div className="door-open">
          <img className="prize" src={prize?.item_img} alt="" />
        </div>
        <div className={`door-close ${on_open_animation ? 'hide' : 'show'}`} />
        {on_open_animation && <img className="smoke-open" src={assets.img_gif_open_door + '?rand=' + randomNumb} alt="" />}
        <div className="lamp" />
        <div className="checkbox" />
      </div>
    );
  };

  const renderPrice = (): JSX.Element | null => {
    if (priceOpen) {
      return (
        <>
          <div className="ico-cp" />
          {oriPrice > 0 && oriPrice !== finalPrice && <span className="ori">{oriPrice}</span>}
          <span>{finalPrice || 'FREE'}</span>
        </>
      );
    }

    return null;
  };

  const handleClickInfo = (): void => {
    dispatch(toggleModal({ name: 'info', state: true }));
  };

  const handleClickInventory = (): void => {
    dispatch(toggleModal({ name: 'inventory', state: true }));
  };

  const handleClickBtnDoor = (type: 'open' | 'breach'): void => {
    if (type === 'open') {
      if (info.cp < finalPrice) {
        dispatch(catch_error('not_enough_cp'));
        return;
      }

      if (box_reminder_open_door) {
        dispatch(open_door(checkedDoors));
      } else {
        dispatch(toggleModal({ name: 'confirm', state: true, data: { type: 'open_door', doors: checkedDoors, price: finalPrice } }));
      }
    }

    if (type === 'breach') {
      dispatch(toggleModal({ name: 'breach_door', state: true, data: { type: 'breach_door' } }));
    }
  };

  const [checkedDoors, setCheckedDoor] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const { tr, info, assets, on_open_animation, on_breach_animation, temp_prizes, box_reminder_open_door } = useAppSelector((state) => state.main);
  const { name } = useAppSelector((state) => state.modal);

  const priceOpen = info.opens.find((open: any) => open.draw_amount === checkedDoors.length);
  const oriPrice: number = priceOpen?.original_price;
  const finalPrice: number | string = priceOpen?.draw_price || tr.txt_free;
  const randomNumb: number = Math.random();

  useEffect(() => {
    if (!name.congrat && !name.breach_door) return;

    setCheckedDoor([]);
  }, [name.congrat, name.breach_door]);

  return (
    <div className="doors">
      <Button className="btn-info" onClick={handleClickInfo} />
      <div className="steel">
        <div className="title" />
      </div>
      <div className="door-wrapper">
        <Door number={0} />
        <Door number={1} />
        <Door number={2} />
      </div>
      <img className={`smoke-breach ${on_breach_animation ? '' : 'hide'}`} src={assets.img_gif_breach_door} alt="" />
      <div className="btn-wrapper">
        <Button className={`btn-open ${checkedDoors.length === 0 ? 'disabled' : ''} `} onClick={() => handleClickBtnDoor('open')}>
          <span>{tr.txt_btn_open_door}</span>
          {renderPrice()}
        </Button>
        <Button className="btn-breach" onClick={() => handleClickBtnDoor('breach')}>
          {tr.txt_btn_breach_door}
          <div className="glow" />
        </Button>
      </div>
      <Button className="btn-inventory" onClick={handleClickInventory} />
    </div>
  );
};

export default Doors;
