import React, { ReactNode, useEffect, useState } from 'react';
import './index.scss';
import stringReplace from 'react-string-replace';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleModal } from 'redux/actions/modal';
import { breach_door, catch_error } from 'redux/actions/main';

import FrameGrenade from './FrameGrenade';
import { Button } from 'components';

import BaseModal from 'helper/component/basemodal';
import { audio } from 'helper/audio';

type GrenadeType = {
  breach_amount: number;
  breach_cost: number;
  img: string;
};

const BreachDoor = () => {
  const handleClickBtn = (): void => {
    if (!selectedAmount) return;

    const { breach_amount, breach_cost } = info.breaches.find((item: GrenadeType) => item.breach_amount === selectedAmount);

    if (info.cp < breach_cost) {
      dispatch(catch_error('not_enough_cp'));
      return;
    }

    dispatch(toggleModal({ name: 'breach_door', state: false }));

    if (box_reminder_breach_door) {
      dispatch(breach_door(selectedAmount));
    } else {
      dispatch(toggleModal({ name: 'confirm', state: true, data: { type: 'breach_door', amount: breach_amount, price: breach_cost } }));
    }
  };

  const handleClickGrenade = (amount: number): void => {
    audio(assets.sound_click).play();
    setSelectedAmount(amount === selectedAmount ? 0 : amount);
  };

  const renderTextDesc = (): ReactNode => {
    let replacedText = stringReplace(tr.txt_headline_breach_door, '{spin_qty}', (match, i) => <span key={i}>{selectedAmount}</span>);
    return replacedText;
  };

  const [selectedAmount, setSelectedAmount] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { tr, assets, info, box_reminder_breach_door } = useAppSelector((state) => state.main);
  const { name } = useAppSelector((state) => state.modal);

  useEffect(() => {
    if (!name.breach_door) return;

    setSelectedAmount(0);
  }, [name.breach_door]);

  return (
    <BaseModal tag="breach_door" title={'BREACH DOOR'}>
      <div className="title" />
      <p className={selectedAmount ? '' : 'hide'}>{renderTextDesc()}</p>
      <div className="frame-wrapper">
        {info.breaches.map((item: GrenadeType, i: number) => (
          <FrameGrenade
            key={i}
            img={item.img}
            qty={item.breach_amount}
            price={item.breach_cost}
            isSelected={item.breach_amount === selectedAmount}
            onClick={() => handleClickGrenade(item.breach_amount)}
          />
        ))}
      </div>
      <Button className={`btn-confirm regular yellow ${selectedAmount !== 0 ? '' : 'disabled'}`} onClick={handleClickBtn}>
        {tr.txt_btn_confirm}
      </Button>
    </BaseModal>
  );
};

export default BreachDoor;
