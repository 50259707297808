import React from 'react';

type Props = {
  img: string;
  qty: number;
  price: number;
  isSelected: boolean;
  onClick: () => void;
};

const FrameGrenade = ({ img, qty, price, isSelected, onClick }: Props) => {
  return (
    <div className={`frame-grenade ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <img className="granade" src={img} alt="" />
      <div className="qty">x{qty}</div>
      <div className="price">
        <div className="cp" />
        <span>{price}</span>
      </div>
    </div>
  );
};

export default FrameGrenade;
