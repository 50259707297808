import React, { useEffect } from 'react';
import './index.scss';
import stringReplace from 'react-string-replace';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { breach_door, claim_item, exchange_item, purchase_item, open_door, put_data } from 'redux/actions/main';
import { toggleModal } from 'redux/actions/modal';

import { Button } from 'components';

import BaseModal from 'helper/component/basemodal';
import { audio } from 'helper/audio';

const Confirm = () => {
  const handleClickBtn = (btnType: 'back' | 'confirm'): void => {
    if (btnType === 'back') {
      dispatch(put_data('box_reminder_' + type, false));

      if (type === 'open_door') {
      }

      if (type === 'breach_door') {
        dispatch(toggleModal({ name: 'breach_door', state: true }));
      }

      if (type === 'exchange' || type === 'claim') {
        dispatch(toggleModal({ name: 'inventory', state: true }));
      }

      if (type === 'purchase') {
        dispatch(toggleModal({ name: 'purchase', state: true }));
      }
    }

    if (btnType === 'confirm') {
      if (type === 'open_door') {
        dispatch(open_door(data.doors));
      }

      if (type === 'breach_door') {
        dispatch(breach_door(data.amount));
      }

      if (type === 'exchange') {
        dispatch(exchange_item(data.items));
      }

      if (type === 'claim') {
        dispatch(claim_item(data.items));
      }

      if (type === 'purchase') {
        dispatch(purchase_item(data.id));
      }
    }

    dispatch(toggleModal({ name: 'confirm', state: false }));
  };

  const toggleCheckBox = (): void => {
    audio(assets.sound_click).play();
    dispatch(put_data('box_reminder_' + type, !isChecked));
  };

  const checkIsChecked = (): boolean => {
    if (type === 'open_door') return box_reminder_open_door;
    if (type === 'breach_door') return box_reminder_breach_door;
    if (type === 'exchange') return box_reminder_exchange;
    if (type === 'claim') return box_reminder_claim;
    if (type === 'purchase') return box_reminder_purchase;
    return false;
  };

  const renderTextDesc = (): string => {
    let replacedText: any;

    if (type === 'open_door') {
      replacedText = stringReplace(tr.txt_confirm_open_door, '{door_qty}', (match, i) => <span key={'x' + i}>{data.doors?.length}</span>);
      replacedText = stringReplace(replacedText, '{cp_price}', (match, i) => <span key={'y' + i}>{data.price}</span>);
    }

    if (type === 'breach_door') {
      replacedText = stringReplace(tr.txt_confirm_breach_door, '{door_qty}', (match, i) => <span key={'x' + i}>{data.amount}</span>);
      replacedText = stringReplace(replacedText, '{cp_price}', (match, i) => <span key={'y' + i}>{data.price}</span>);
    }

    if (type === 'exchange') {
      replacedText = stringReplace(tr.txt_convert_to_token, '{item_qty}', (match, i) => <span key={'x' + i}>{data.items?.length}</span>);
      replacedText = stringReplace(replacedText, '{token_qty}', (match, i) => <span key={'y' + i}>{data.totalToken}</span>);
    }

    if (type === 'claim') {
      replacedText = stringReplace(tr.txt_confirm_claim_item, '{item_qty}', (match, i) => <span key={'x' + i}>{data.items?.length}</span>);
    }

    if (type === 'purchase') {
      replacedText = stringReplace(tr.txt_exchange_to_item, '{token_qty}', (match, i) => <span key={'x' + i}>{data.price}</span>);
      replacedText = stringReplace(replacedText, '{item_name}', (match, i) => <span key={'y' + i}>{data.name}</span>);
    }

    return replacedText;
  };

  const dispatch = useAppDispatch();
  const { tr, assets, box_reminder_open_door, box_reminder_breach_door } = useAppSelector((state) => state.main);
  const { box_reminder_exchange, box_reminder_claim, box_reminder_purchase } = useAppSelector((state) => state.main);
  const { name, data } = useAppSelector((state) => state.modal);
  const { type } = data;
  const isChecked: boolean = checkIsChecked();
  const text: string = renderTextDesc();

  useEffect(() => {
    if (!name.confirm) return;
  }, [name.confirm]);

  return (
    <BaseModal tag="confirm" title={tr.txt_title_confirm} onExit={() => handleClickBtn('back')}>
      <h1>{tr.txt_title_confirm}</h1>
      <p>{text}</p>
      <div className="reminder" onClick={toggleCheckBox}>
        <div className={`box ${isChecked ? 'checked' : ''}`} />
        <div className="text">{tr.txt_dont_show}</div>
      </div>
      <div className="btn-wrapper">
        <Button className="regular" onClick={() => handleClickBtn('back')}>
          {tr.txt_btn_back}
        </Button>
        <Button className="regular yellow" onClick={() => handleClickBtn('confirm')}>
          {tr.txt_btn_confirm}
        </Button>
      </div>
    </BaseModal>
  );
};

export default Confirm;
