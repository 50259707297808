import React, { useEffect, useState } from 'react';
import './index.scss';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { get_history, put_data } from 'redux/actions/main';

import TabContent from './TabContent';

import BaseModal from 'helper/component/basemodal';
import { throttle } from 'helper';
import { audio } from 'helper/audio';

type Tab = {
  id: number;
  text: string;
};

const Info: React.FC = () => {
  const onExit = (): void => {
    !have_seen_tutorial && dispatch(put_data('have_seen_tutorial', true));
  };

  const handleClickTab = (id: number) => {
    if (activeTab === id) return;

    audio(assets.sound_click).play();

    setActiveTab(id);

    !have_seen_tutorial && dispatch(put_data('have_seen_tutorial', true));

    if (id === 3) {
      throttle('history', () => dispatch(get_history()), 15000);
    }
  };

  const [activeTab, setActiveTab] = useState<number>(1);
  const dispatch = useAppDispatch();
  const { name } = useAppSelector((state) => state.modal);
  const { tr, assets, have_seen_tutorial } = useAppSelector((state) => state.main);

  const tabs: Tab[] = [
    { id: 1, text: tr.txt_title_tutorial },
    { id: 2, text: tr.txt_title_rules },
    { id: 3, text: tr.txt_title_history },
  ];

  useEffect(() => {
    if (!name.info) return;

    setActiveTab(1);
  }, [name.info]);

  return (
    <BaseModal tag="info" title={'INFO'} onExit={onExit}>
      <div className="tabs">
        {tabs.map(({ id, text }) => (
          <div key={id} className={`tab ${id === activeTab ? 'active' : ''}`} onClick={() => handleClickTab(id)}>
            <div className={`mark ${have_seen_tutorial ? 'hide' : ''}`} />
            {text}
          </div>
        ))}
      </div>
      <TabContent activeTab={activeTab} />
    </BaseModal>
  );
};

export default Info;
