import React from 'react';
import CountUp from 'react-countup';

type Props = {
  end: number;
  duration?: number | 10;
};

const Number = ({ end, duration = 0.5 }: Props) => {
  return <CountUp end={end} duration={duration} separator="." preserveValue />;
};

export default Number;
