const auth_state = {
  param: false,
};

const auth = (state = auth_state, action: any) => {
  switch (action.type) {
    case 'SET_PARAM':
      return { ...state, param: action.data };
    default:
      return state;
  }
};

export default auth;
