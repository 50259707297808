import { combineReducers } from 'redux';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './reducers/auth';
import main from './reducers/main';
import modal from './reducers/modal';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [''],
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['err_code'],
};

const mainPersistConfig = {
  key: 'main',
  storage: storage,
  whitelist: [
    'histories',
    'inventories',
    'stores',
    'have_seen_tutorial',
    'box_reminder_open_door',
    'box_reminder_breach_door',
    'box_reminder_convert',
    'box_reminder_claim',
    'box_reminder_exchange',
  ],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  main: persistReducer(mainPersistConfig, main),
  modal,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
