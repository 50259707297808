import axios from 'axios';
import { AppDispatch } from 'redux/store';
import { toggleModal, toggleToast } from './modal';
import { deleteThrottles, err_msg, groupingPrizes } from 'helper';
import { audio } from 'helper/audio';

const common = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/tr_common/`,
  withCredentials: false,
});

const cdn = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
  withCredentials: false,
});

const rarity = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/glb_codm_rarity/`,
  withCredentials: false,
});

export const toggle_loader = (data: boolean) => ({
  type: 'TOGGLE_LOADER',
  data: data,
});

export const put_data = (key: string, data: any) => ({
  type: 'PUT_DATA',
  key,
  data,
});

export const get_translate = (language: string) => async (dispatch: AppDispatch) => {
  dispatch(toggle_loader(true));

  const jsonParam = `${language}.json?${Math.random() * 10000}`;

  const main_localize = cdn.get(jsonParam);
  const common_localize = common.get(jsonParam);
  const rarity_localize = rarity.get(`data.json?${Math.random() * 10000}`);

  try {
    const resp = await axios.all([main_localize, common_localize, rarity_localize]);
    const json = { ...resp[0].data, ...resp[1].data, ...resp[2].data };
    const assetPrefixs = ['img_', 'rarity_', 'sound_'];

    const assets = Object.keys(json)
      .filter((key) => assetPrefixs.find((prefix) => key.includes(prefix)))
      .reduce((obj, key) => Object.assign(obj, { [key]: json[key] }), {});

    const tr = Object.keys(json)
      .filter((key) => !assetPrefixs.find((prefix) => key.includes(prefix)))
      .reduce((obj, key) => Object.assign(obj, { [key]: json[key] }), {});

    dispatch(put_data('assets', assets));
    dispatch(put_data('tr', tr));
  } catch (err: any) {
    dispatch(catch_error(err_msg(err)));
  } finally {
    dispatch(toggle_loader(false));
  }
};

export const get_info = () => async (dispatch: AppDispatch, getState: any) => {
  dispatch(toggle_loader(true));

  try {
    const resp = await axios.get('api/info/');
    if (resp.status !== 200) throw new Error();

    dispatch(put_data('info', resp.data));

    const { have_seen_tutorial } = getState().main;
    if (!have_seen_tutorial) dispatch(toggleModal({ name: 'info', state: true }));
  } catch (err: any) {
    dispatch(catch_error(err_msg(err)));
  } finally {
    dispatch(toggle_loader(false));
  }
};

export const get_history = () => async (dispatch: AppDispatch) => {
  dispatch(toggle_loader(true));

  try {
    const resp = await axios.get('api/history/');
    if (resp.status !== 200) throw new Error();

    const histories = resp.data.history;
    dispatch(put_data('histories', histories));
  } catch (err: any) {
    dispatch(catch_error(err_msg(err)));
  } finally {
    dispatch(toggle_loader(false));
  }
};

export const get_inventory = () => async (dispatch: AppDispatch, getState: any) => {
  dispatch(toggle_loader(true));

  try {
    const resp = await axios.get('api/inventory/');
    if (resp.status !== 200) throw new Error();

    const inventories = resp.data.items;
    dispatch(put_data('inventories', inventories));
  } catch (err: any) {
    dispatch(catch_error(err_msg(err)));
  } finally {
    dispatch(toggle_loader(false));
  }
};

export const get_store = () => async (dispatch: AppDispatch, getState: any) => {
  dispatch(toggle_loader(true));

  try {
    const resp = await axios.get('api/store/');
    if (resp.status !== 200) throw new Error();

    const stores = resp.data.items;
    dispatch(put_data('stores', stores));
  } catch (err: any) {
    dispatch(catch_error(err_msg(err)));
  } finally {
    dispatch(toggle_loader(false));
  }
};

export const open_door = (doors: number[]) => async (dispatch: AppDispatch, getState: any) => {
  dispatch(toggle_loader(true));

  try {
    const resp = await axios.post('api/open/', { doors });
    if (resp.status !== 200) throw new Error();

    const { sound_open_door } = getState().main.assets;
    audio(sound_open_door).play();

    const prizes = resp.data.items;
    const myPrizes = prizes.filter((prize: any, i: number) => doors.includes(i));
    dispatch(put_data('temp_prizes', prizes));
    dispatch(put_data('on_open_animation', true));

    setTimeout(() => {
      dispatch(toggleModal({ name: 'congrat', state: true, data: { type: 'open_door', prizes: myPrizes } }));
      dispatch(put_data('temp_prizes', false));
      dispatch(put_data('on_open_animation', false));
    }, 3500);

    deleteThrottles(['history', 'inventory', 'store']);
  } catch (err: any) {
    dispatch(catch_error(err_msg(err)));
  } finally {
    dispatch(toggle_loader(false));
  }
};

export const breach_door = (amount: number) => async (dispatch: AppDispatch, getState: any) => {
  dispatch(toggle_loader(true));

  try {
    const resp = await axios.post('api/breach/', { amount });
    if (resp.status !== 200) throw new Error();

    const { sound_breach_door } = getState().main.assets;
    audio(sound_breach_door).play();

    const prizes = resp.data.items;
    dispatch(put_data('on_breach_animation', prizes));

    setTimeout(() => {
      dispatch(put_data('on_breach_animation', false));
      dispatch(toggleModal({ name: 'congrat', state: true, data: { type: 'breach_door', prizes } }));
    }, 2000);

    deleteThrottles(['history', 'inventory', 'store']);
  } catch (err: any) {
    dispatch(catch_error(err_msg(err)));
  } finally {
    dispatch(toggle_loader(false));
  }
};

export const exchange_item = (items: number[]) => async (dispatch: AppDispatch, getState: any) => {
  dispatch(toggle_loader(true));

  try {
    const resp = await axios.post('api/exchange/', { items });
    if (resp.status !== 200) throw new Error();

    const tokens = resp.data.tokens || 50;
    const tokenImg = getState().main.assets.img_token;
    const prizes = [{ rarity: 'grandprize', item_img: tokenImg, item_amount: tokens }];
    dispatch(toggleModal({ name: 'congrat', state: true, data: { type: 'exchange', prizes } }));

    deleteThrottles(['inventory']);
  } catch (err: any) {
    dispatch(catch_error(err_msg(err)));
  } finally {
    dispatch(toggle_loader(false));
  }
};

export const claim_item = (items: number[]) => async (dispatch: AppDispatch) => {
  dispatch(toggle_loader(true));

  try {
    const resp = await axios.post('api/claim/', { items });
    if (resp.status !== 200) throw new Error();

    const prizes = resp.data.items || [];
    const groupedPrizes = groupingPrizes(prizes, 'item_type_id');
    dispatch(toggleModal({ name: 'congrat', state: true, data: { type: 'claim', prizes: groupedPrizes } }));

    deleteThrottles(['history', 'inventory']);
  } catch (err: any) {
    dispatch(catch_error(err_msg(err)));
  } finally {
    dispatch(toggle_loader(false));
  }
};

export const purchase_item = (item_id: number) => async (dispatch: AppDispatch, getState: any) => {
  dispatch(toggle_loader(true));

  try {
    const resp = await axios.post('api/purchase/', { item_id });
    if (resp.status !== 200) throw new Error();

    const prizes = [resp.data.item];
    dispatch(toggleModal({ name: 'congrat', state: true, data: { type: 'purchase', prizes } }));

    deleteThrottles(['history', 'store']);
  } catch (err: any) {
    dispatch(catch_error(err_msg(err)));
  } finally {
    dispatch(toggle_loader(false));
  }
};

export const catch_error = (errMsg: string) => (dispatch: AppDispatch) => {
  dispatch(toggleToast({ state: true, msg: errMsg }));
  dispatch(toggle_loader(false));
};
