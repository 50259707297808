import React, { useEffect, useState } from 'react';
import './index.scss';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleModal } from 'redux/actions/modal';
import { claim_item, exchange_item, get_inventory } from 'redux/actions/main';

import { Button, Currency, Frame } from 'components';

import BaseModal from 'helper/component/basemodal';
import { throttle } from 'helper';
import { audio } from 'helper/audio';
import { Prize } from 'helper/interface';

const Inventory = () => {
  const handleClickBtn = (type: 'exchange' | 'claim'): void => {
    dispatch(toggleModal({ name: 'inventory', state: false }));

    const items = selectedFrame;
    const totalToken = inventories.filter((item, i) => selectedFrame.includes(i)).reduce((acc, obj: any) => acc + obj.exchange_rate, 0);

    if (type === 'exchange') {
      if (box_reminder_exchange) {
        dispatch(exchange_item(selectedFrame));
      } else {
        dispatch(toggleModal({ name: 'confirm', state: true, data: { type: 'exchange', items, totalToken } }));
      }
    }

    if (type === 'claim') {
      if (box_reminder_claim) {
        dispatch(claim_item(selectedFrame));
      } else {
        dispatch(toggleModal({ name: 'confirm', state: true, data: { type: 'claim', items } }));
      }
    }
  };

  const handleClickAutoSelect = (): void => {
    audio(assets.sound_click).play();

    let frames: number[] = [];

    if (!isSelectedFull) {
      frames = inventories.filter((item: any, i: number) => i < maxSelect).map((item: any, i: number) => i);
    }

    setSelectedFrame(frames);
  };

  const handleClickFrame = (index: number): void => {
    if (isSelectedFull && !checkIsSelected(index)) return;

    audio(assets.sound_click).play();

    let frames = [];
    frames = [...selectedFrame];

    if (checkIsSelected(index)) {
      frames = frames.filter((frame) => frame !== index);
    } else {
      frames.push(index);
    }

    setSelectedFrame(frames);
  };

  const [selectedFrame, setSelectedFrame] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const { tr, assets, inventories, box_reminder_exchange, box_reminder_claim } = useAppSelector((state) => state.main);
  const { name } = useAppSelector((state) => state.modal);

  const checkIsSelected = (id: number) => selectedFrame.includes(id);
  const maxSelect: number = 10;
  const isSelectedFull: boolean = inventories.length > 0 && (selectedFrame.length === maxSelect || selectedFrame.length === inventories.length);

  useEffect(() => {
    if (!name.inventory) return;

    setSelectedFrame([]);

    throttle('inventory', () => dispatch(get_inventory()), 15000);
  }, [dispatch, name.inventory]);

  return (
    <BaseModal tag="inventory" title={tr.txt_title_inventory}>
      <h1>{tr.txt_title_inventory}</h1>
      <p>{tr.txt_note_inventory}</p>
      <Currency className="token" type="token" animationDuration={0} />
      <div className="count">
        {selectedFrame.length} / {maxSelect}
      </div>
      <div className="checkbox-wrapper" onClick={handleClickAutoSelect}>
        <span>{tr.txt_auto_select}</span>
        <div className={`checkbox ${isSelectedFull ? 'checked' : ''}`} />
      </div>
      <div className="prize-wrapper">
        {inventories.map((item: Prize, key: number) => (
          <Frame
            key={key}
            className="selectable"
            rarity={item.rarity}
            img={item.item_img}
            qty={item.item_amount}
            name={item.item_name}
            isSelected={checkIsSelected(key)}
            onClickFrame={() => handleClickFrame(key)}
          />
        ))}
      </div>
      <div className="btn-wrapper">
        <Button className={`regular ${selectedFrame.length > 0 ? 'yellow' : 'disabled'}`} onClick={() => handleClickBtn('exchange')}>
          {tr.txt_btn_exchange}
        </Button>
        <Button className={`regular ${selectedFrame.length > 0 ? 'yellow' : 'disabled'}`} onClick={() => handleClickBtn('claim')}>
          {tr.txt_btn_claim}
        </Button>
      </div>
    </BaseModal>
  );
};

export default Inventory;
