import React from 'react';
import { format } from 'date-fns';

import { useAppSelector } from 'redux/hooks';

import Carousel from './Carousel';

type Props = {
  activeTab: number;
};

type History = {
  time: string;
  action: string;
  item: string;
  action_count: number;
};

const TabContent = ({ activeTab }: Props) => {
  const renderTextActionType = (type: string, amount: number): string => {
    if (type === 'open') return tr.txt_type_open_door.replace('{door_qty}', amount);
    if (type === 'breach') return tr.txt_type_breach_door;
    if (type === 'claim') return tr.txt_type_claim_inventory;
    if (type === 'exchange') return tr.txt_type_exchange;
    return type;
  };

  const { tr, histories } = useAppSelector((state) => state.main);

  if (activeTab === 1) {
    return (
      <div key={activeTab} className="content-wrapper">
        <Carousel images={tr.tutorial} />
      </div>
    );
  }

  if (activeTab === 2) {
    return (
      <div key={activeTab} className="content-wrapper">
        <div className="event-info" dangerouslySetInnerHTML={{ __html: tr.detail_rules }} />
      </div>
    );
  }

  if (activeTab === 3) {
    return (
      <div key={activeTab} className="content-wrapper">
        <table className="history">
          <thead>
            <tr>
              <td>{tr.txt_history_date}</td>
              <td>{tr.txt_history_type}</td>
              <td>{tr.txt_history_item}</td>
            </tr>
          </thead>
          <tbody>
            {histories.map((history: History, i: number) => (
              <tr key={i}>
                <td>{format(new Date(history.time), 'dd LLLL')}</td>
                <td>{renderTextActionType(history.action, history.action_count)}</td>
                <td>{history.item}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

export default TabContent;
