import React from 'react';
import Slider from 'react-slick';

import { Button } from 'components';

type CarouselType = {
  images: { id: string; image: string }[];
};

const Carousel = ({ images = [] }: CarouselType) => {
  const NextArrow = ({ className, onClick }: any): JSX.Element => <Button className={className} onClick={onClick} />;
  const PrevArrow = ({ className, onClick }: any): JSX.Element => <Button className={className} onClick={onClick} />;

  const settings = {
    className: 'carousel',
    dotsClass: 'carousel-dots',
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings}>
      {images.map((data) => (
        <img key={data.id} src={data.image} alt="" />
      ))}
    </Slider>
  );
};

export default Carousel;
