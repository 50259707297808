const initialState = {
  msg: '',
  closeIcon: true,
  toast: false,
  type: 'error',
  name: {
    info: false,
    breach_door: false,
    confirm: false,
    congrat: false,
    inventory: false,
    purchase: false,
  },
  data: false,
};

const modal = (state = initialState, action: any) => {
  switch (action.type) {
    case 'TOGGLE_MODAL':
      return {
        ...state,
        name: { ...state.name, [action.payload.name]: action.payload.state },
        data: action.payload.data ? action.payload.data : state.data,
      };
    case 'TOGGLE_TOAST':
      return {
        ...state,
        msg: action.payload.msg,
        toast: action.payload.state,
        closeIcon: action.payload.closeIcon,
        type: 'error',
      };
    default:
      return state;
  }
};

export default modal;
