import { Howl } from 'howler';

export const audio = (url, option) => {
  const sound = new Howl({
    src: [url],
    ...option,
  });

  return sound;
};
