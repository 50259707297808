import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

// Redux
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { set_token } from 'redux/actions/auth';

// Component
import { ModalCont } from 'components';
import { Fallback, LoadingScreen, Loader, NotFound, componentLoader, setCssVariable } from 'helper';
import ViewportRestrict from 'helper/component/viewport';

// Pages
const Home = lazy(() => componentLoader(() => import('pages/Home')));

export const Main = () => {
  const dispatch = useAppDispatch();
  const { loader, assets } = useAppSelector((state) => state.main);

  useEffect(() => {
    dispatch(set_token());
  }, [dispatch]);

  useEffect(() => {
    if (!assets) return;
    setCssVariable(assets);
  }, [assets]);

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <div id="Main" className="main-panel">
        <ErrorBoundary fallback={Fallback}>
          <Suspense fallback={<LoadingScreen />}>
            <ViewportRestrict display={true} type="portrait" />
            <Loader loader={loader} />
            <ModalCont />
            <div className="content-container">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </Suspense>
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
};

export default Main;
