import React from 'react';
import './index.scss';

import { useSelector } from 'react-redux';

import Error from './Error';
import Info from './Info';
import BreachDoor from './BreachDoor';
import Confirm from './Confirm';
import Congrat from './Congrat';
import Inventory from './Inventory';
import Purchase from './Purchase';

const ModalCont = () => {
  const { info } = useSelector((state) => state.main);

  if (!info) {
    return <Error />;
  }

  return (
    <>
      <Error />
      <Info />
      <BreachDoor />
      <Confirm />
      <Congrat />
      <Inventory />
      <Purchase />
    </>
  );
};

export default ModalCont;
