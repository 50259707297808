import { MainStateType } from 'helper/interface';

const main_state: MainStateType = {
  loader: false,
  tr: false,
  assets: false,
  info: false,
  histories: [],
  inventories: [],
  stores: [],
  on_open_animation: false,
  on_breach_animation: false,
  temp_prizes: [],
  have_seen_tutorial: false,
  box_reminder_open_door: false,
  box_reminder_breach_door: false,
  box_reminder_exchange: false,
  box_reminder_claim: false,
  box_reminder_purchase: false,
};

const main = (state = main_state, action: any) => {
  switch (action.type) {
    case 'PUT_DATA':
      return { ...state, [action.key]: action.data };
    case 'TOGGLE_LOADER':
      return { ...state, loader: action.data };
    default:
      return state;
  }
};

export default main;
