import axios from 'axios';
import { get_translate, get_info, catch_error } from './main';

import { ls, err_msg, getAllUrlParam, setAttributes, removeParamUrl } from 'helper';

export const set_param = (data: any) => ({
  type: 'SET_PARAM',
  data,
});

export const set_token = () => async (dispatch: any, getState: any) => {
  const { auth } = getState();
  const params = getAllUrlParam();

  let param = Object.keys(params).length === 0 ? auth?.param : params;

  if (param?.access_token || param?.gop_access_token) {
    try {
      await dispatch(getJWT(param));
      dispatch(set_param(param));
      dispatch(get_info());
    } catch (err: any) {
      dispatch(catch_error(err_msg(err)));
    }
  } else {
    dispatch(catch_error('not_logged_in'));
  }

  dispatch(get_translate('ID'));
  setAttributes(param.lang);
};

export const getJWT = (param: any) => async () => {
  const resp = await axios.get(`oauth/callback/`, { params: param });
  const tokenHeaderName = process.env.REACT_APP_TOKEN_HEADER_NAME;

  // @ts-ignore
  axios.defaults.headers[tokenHeaderName] = resp.data.token;

  ls.setItem('token', resp.data.token);
  removeParamUrl();
};
