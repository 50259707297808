import React, { useEffect } from 'react';
import './index.scss';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleModal } from 'redux/actions/modal';
import { purchase_item, get_store, catch_error } from 'redux/actions/main';

import { Currency, Frame } from 'components';

import BaseModal from 'helper/component/basemodal';
import { Prize } from 'helper/interface';
import { throttle } from 'helper';

const Purchase = () => {
  const handleClickExchange = (prize: Prize, i: number): void => {
    const { cost: price, item_name: name } = prize;
    const id = i;

    if (info.tokens < Number(price)) {
      dispatch(catch_error('not_enough_token'));
      return;
    }

    dispatch(toggleModal({ name: 'purchase', state: false }));

    if (box_reminder_purchase) {
      dispatch(purchase_item(id));
    } else {
      dispatch(toggleModal({ name: 'confirm', state: true, data: { type: 'purchase', id, price, name } }));
    }
  };

  const dispatch = useAppDispatch();
  const { tr, info, stores, box_reminder_purchase } = useAppSelector((state) => state.main);
  const { name } = useAppSelector((state) => state.modal);

  useEffect(() => {
    if (!name.purchase) return;

    throttle('store', () => dispatch(get_store()), 15000);
  }, [dispatch, name.purchase]);

  return (
    <BaseModal tag="purchase" title={tr.txt_title_exchange_center}>
      <h1>{tr.txt_title_exchange_center}</h1>
      <Currency className="token" type="token" animationDuration={0} />
      <div className="prize-wrapper">
        {stores.map((prize: Prize, i: number) => (
          <Frame
            key={i}
            className="exc"
            rarity={prize.rarity}
            qty={prize.item_amount}
            img={prize.item_img}
            name={prize.item_name}
            price={prize.cost}
            currentPurchases={prize.current_purchases}
            maxPurchases={prize.max_purchases}
            isDisabled={(prize.current_purchases || 0) >= (prize.max_purchases || 1)}
            hasPurchaseLimit
            onClickBtn={() => handleClickExchange(prize, i)}
          />
        ))}
      </div>
    </BaseModal>
  );
};

export default Purchase;
