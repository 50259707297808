interface ToggleModal {
  name: string;
  state?: boolean;
  closeIcon?: boolean;
  parentId?: boolean;
  data?: {};
}

interface ToggleToast {
  state: boolean;
  msg: string;
}

export const toggleModal =
  ({ name, state = false, closeIcon = true, parentId = false, data }: ToggleModal) =>
  (dispatch: any, getState: any) => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        name,
        state,
        closeIcon,
        parentId,
        data,
      },
    });
  };

export const toggleToast =
  ({ state = false, msg = '' }: ToggleToast) =>
  (dispatch: any) => {
    dispatch({
      type: 'TOGGLE_TOAST',
      payload: {
        state,
        msg,
      },
    });
  };

export const setMsg = (msg: string) => ({
  type: 'SET_MSG',
  payload: {
    msg,
  },
});

export const showCloseIcon = (closeIcon: boolean = true) => ({
  type: 'SHOW_CLOSE',
  payload: closeIcon,
});
