import React from 'react';
import './index.scss';

import { useAppDispatch } from 'redux/hooks';
import { toggleModal } from 'redux/actions/modal';

import { Button } from 'components';

const Exchange: React.FC = () => {
  const handleClickExchange = (): void => {
    dispatch(toggleModal({ name: 'purchase', state: true }));
  };

  const dispatch = useAppDispatch();

  return (
    <div className="exchange">
      <Button className="btn-exchange" onClick={handleClickExchange} />
    </div>
  );
};

export default Exchange;
