import React from 'react';
import './index.scss';

import { Button } from 'components';

import { formatQty } from 'helper';

type Props = {
  className: string;
  rarity: string;
  img: string;
  qty: number;
  name: string;
  price?: number;
  total?: number;
  currentPurchases?: number;
  maxPurchases?: number;
  isGrandprize?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  hasPurchaseLimit?: boolean;
  onClickFrame?: () => void;
  onClickBtn?: () => void;
};

const Frame = ({
  className,
  rarity,
  qty,
  name,
  img,
  price,
  total,
  currentPurchases,
  maxPurchases,
  isGrandprize,
  isDisabled,
  isSelected,
  hasPurchaseLimit,
  onClickFrame = () => {},
  onClickBtn = () => {},
}: Props) => {
  return (
    <div className={`frame ${isDisabled ? 'disabled' : ''} ${rarity} ${className}`} onClick={onClickFrame}>
      <img className="prize" src={img} alt="" />
      <div className={`gp-fx ${isGrandprize ? 'show' : ''}`} />
      <div className={`checkbox ${isSelected ? 'checked' : ''}`} />
      <div className={`limit ${hasPurchaseLimit ? '' : 'hide'}`}>
        {currentPurchases}/{maxPurchases}
      </div>
      <div className={`total ${Number(total) > 1 ? 'show' : ''}`}>{formatQty(total)}</div>
      <div className="qty">{formatQty(qty)}</div>
      <div className="name">{name}</div>
      <Button className="btn-exchange small" onClick={onClickBtn}>
        <div className="ico-token" />
        <span>{price}</span>
      </Button>
    </div>
  );
};

export default Frame;
