import React from 'react';
import './index.scss';

import { useAppSelector } from 'redux/hooks';

import { Currency } from 'components';

const UserInfo: React.FC = () => {
  const { tr, info } = useAppSelector((state) => state.main);

  return (
    <div className="user-info">
      <div className="nickname">
        {tr.txt_hi} {info.nickname}
      </div>
      <div className="amount">
        <Currency type="token" />
        <Currency type="cp" />
      </div>
    </div>
  );
};

export default UserInfo;
