import React, { useEffect } from 'react';
import Modal from 'react-responsive-modal';
import './index.scss';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleToast } from 'redux/actions/modal';

const Error = () => {
  const closeToast = (): void => {
    if (!isClosable) return;

    dispatch(toggleToast({ state: false, msg: '' }));
  };

  const generateMsg = (errCode: string): string => {
    switch (errCode) {
      case 'not_enough_cp':
        return tr.txt_not_enough_cp;
      case 'not_enough_token':
        return tr.txt_not_enough_token;
      default:
        return errCode;
    }
  };

  const dispatch = useAppDispatch();
  const { tr } = useAppSelector((state) => state.main);
  const { toast, msg } = useAppSelector((state) => state.modal);

  const errCodesNotClosable: any = {
    not_logged_in: 'Not Login',
    TOAST_EVENTOVER: 'Event ended',
    TOAST_EVENT_CLOSED_AWHILE: 'The event is closed temporary',
    TOAST_EVENT_END: 'Event ended',
    TOAST_EVENT_NOTOPEN: "Event hasn't started",
    TOAST_LOGIN_FAILED: 'Login failed',
    TOAST_WRONG_REGION: 'This event is not available for your region',
    VISITOR_NOT_ALLOWED: 'Visitor Not Allowed',
    TOAST_SERVER_BUSY: 'Server busy, please try again later',
    TOAST_SERVER_TIMEOUT: 'Server Timeout',
    TOAST_SERVER_NOTWORK: 'TOAST_SERVER_NOTWORK',
    TOAST_ERROR: 'Please Refresh Your Browser!',
    maintenance: 'Maintenance',
  };

  const isOpen = toast;
  const isClosable = !errCodesNotClosable[msg];

  useEffect(() => {
    if (!toast) return;

    const timerFunc = setTimeout(() => closeToast(), 2000);

    return () => clearTimeout(timerFunc);
    // eslint-disable-next-line
  }, [toast]);

  return (
    <Modal
      open={isOpen}
      onClose={closeToast}
      closeOnOverlayClick={isClosable}
      center
      showCloseIcon={false}
      closeOnEsc={false}
      focusTrapped={false}
      classNames={{
        overlay: 'modal__overlay overlay-toast',
        modal: `modal toast`,
        modalAnimationIn: 'slideInUp',
        modalAnimationOut: 'fadeOut',
      }}
      animationDuration={250}>
      <div className="modal__container toast" onClick={closeToast}>
        <div className="modal__content toast">
          <p>{errCodesNotClosable[msg] || generateMsg(msg)}</p>
        </div>
      </div>
    </Modal>
  );
};

export default Error;
