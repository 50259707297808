import React from 'react';
import './index.scss';

import { useAppSelector } from 'redux/hooks';

import { audio } from 'helper/audio';

type Props = {
  className?: string;
  type?: string;
  children?: React.ReactNode;
  onClick: () => void;
};

const Button = (props: Props) => {
  const { children, className = '', onClick } = props;
  const { assets, on_open_animation, on_breach_animation } = useAppSelector((state) => state.main);

  return (
    <button
      className={className}
      onClick={() => {
        if (on_open_animation || on_breach_animation) return;
        audio(assets.sound_click).play();
        onClick();
      }}>
      {children}
    </button>
  );
};

export default Button;
