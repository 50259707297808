import React from 'react';
import './index.scss';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleModal } from 'redux/actions/modal';
import { get_info } from 'redux/actions/main';

import { Button, Frame } from 'components';

import BaseModal from 'helper/component/basemodal';
import { Prize } from 'helper/interface';

const Congrat = () => {
  const handleClickBtn = (): void => {
    if (type === 'open_door' || type === 'breach_door' || type === 'exchange' || type === 'purchase') {
      dispatch(get_info());
    }

    dispatch(toggleModal({ name: 'congrat', state: false }));
  };

  const renderTextDesc = (): string => {
    if (type === 'open_door') {
      if (isContainGrandprize) {
        if (prizes.length === 1) return tr.txt_note_sent_mailbox;
        return tr.txt_note_sent_item;
      }
      return tr.txt_note_sent_inventory;
    }
    if (type === 'breach_door') return tr.txt_note_sent_item;
    if (type === 'exchange') return tr.txt_token_confirmed;
    if (type === 'claim') return tr.txt_note_sent_mailbox;
    if (type === 'purchase') return tr.txt_note_sent_mailbox;
    return '';
  };

  const dispatch = useAppDispatch();
  const { tr } = useAppSelector((state) => state.main);
  const { data } = useAppSelector((state) => state.modal);
  const { type, prizes = [] } = data;
  const isContainGrandprize = prizes.some((prize: Prize) => prize.is_grandprize);

  return (
    <BaseModal tag="congrat" title={tr.txt_title_congrats}>
      <h1>{tr.txt_title_congrats}</h1>
      <p>{renderTextDesc()}</p>
      <div className="prize-wrapper">
        {prizes.map((prize: Prize, i: number) => (
          <Frame
            key={i}
            className=""
            rarity={prize.rarity}
            total={prize.total}
            img={prize.item_img}
            qty={prize.item_amount}
            name={prize.item_name}
            isGrandprize={prize.is_grandprize || prize.is_cp}
          />
        ))}
      </div>
      <Button className="regular yellow" onClick={handleClickBtn}>
        {tr.txt_btn_confirm}
      </Button>
    </BaseModal>
  );
};

export default Congrat;
