import React, { ReactNode } from 'react';
import Modal from 'react-responsive-modal';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleModal } from 'redux/actions/modal';

import { audio } from 'helper/audio';

interface BaseModalType {
  title?: string;
  children: ReactNode;
  tag: string;
  size?: string;
  onExit?: () => void;
}

function BaseModal({ title, children, tag, size = 'regular', onExit = () => {}, ...props }: BaseModalType) {
  const onClose = () => {
    audio(assets.sound_click).play();
    onExit();
    dispatch(toggleModal({ name: tag, state: false }));
  };

  const dispatch = useAppDispatch();
  const { name } = useAppSelector((state) => state.modal);
  const { assets } = useAppSelector((state) => state.main);

  // @ts-ignore
  const isOpen = name[tag];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      center={true}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScroll={false}
      classNames={{
        modal: `modal modal-${tag} popup-${size}`,
        overlay: `modal__overlay overlay-${tag}`,
        closeButton: `btn-close close-${tag}`,
      }}
      container={document.getElementById('Main')}
      closeIcon={<div className="ico-close" />}
      showCloseIcon={true}
      focusTrapped={false}
      {...props}>
      <div className={`modal__container ${tag}`}>
        <div className="modal__codm" />
        {title && (
          <div className={`modal__title ${tag}`}>
            <div className={`title-${tag}`}>{title}</div>
          </div>
        )}
        <div className={`modal__content ${tag}`}>{children}</div>
      </div>
    </Modal>
  );
}

export default BaseModal;
