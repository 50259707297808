import Fallback from './component/fallback';
import LoadingScreen from './component/loadingscreen';
import Loader from './component/loader';
import NotFound from './component/404';
import { BrowserInfo } from './game';

export { Fallback, LoadingScreen, Loader, NotFound };

export const ls = window.localStorage;

export const CDN = 'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/proper-payday/';

export const setCssVariable = (assets) => {
  const setVariable = (varName, url) => {
    const ele = document.documentElement;
    ele.style.setProperty(varName, `url(${url})`);
  };

  setVariable('--bg-main', assets['img_bg-main']);
  setVariable('--bar', assets['img_bar']);
  setVariable('--bar-inner', assets['img_bar-inner']);
  setVariable('--btn-info', assets['img_btn-info']);
  setVariable('--ico-token', assets['img_ico-token']);
  setVariable('--ico-cp', assets['img_ico-cp']);
  setVariable('--img-steel', assets['img_img-steel']);
  setVariable('--img-title', assets['img_img-title']);
  setVariable('--img-door-close-1', assets['img_img-door-close-1']);
  setVariable('--img-door-close-2', assets['img_img-door-close-2']);
  setVariable('--img-door-close-3', assets['img_img-door-close-3']);
  setVariable('--img-door-open', assets['img_img-door-open']);
  setVariable('--img-lamp', assets['img_lamp']);
  setVariable('--img-lamp-on', assets['img_lamp-on']);
  setVariable('--img-box', assets['img_img-box']);
  setVariable('--img-box-checked', assets['img_img-box-checked']);
  setVariable('--btn-open', assets['img_btn-open']);
  setVariable('--btn-breach', assets['img_btn-breach']);
  setVariable('--btn-inventory', assets['img_btn-inventory']);
  setVariable('--btn-exchange', assets['img_btn-exchange']);
  setVariable('--bg-toast', assets['img_bg-toast']);
  setVariable('--bg-popup', assets['img_bg-popup']);
  setVariable('--bg_popup_overlay', assets['img_bg_popup_overlay']);
  setVariable('--btn-close', assets['img_btn-close']);
  setVariable('--ico-codm', assets['img_ico-codm']);
  setVariable('--btn-tab', assets['img_btn-tab']);
  setVariable('--btn-tab-on', assets['img_btn-tab-on']);
  setVariable('--ico-mark', assets['img_ico-mark']);
  setVariable('--dot', assets['img_dot']);
  setVariable('--dot-on', assets['img_dot-on']);
  setVariable('--btn-arrow', assets['img_btn-arrow']);
  setVariable('--img-box-popup', assets['img_img-box-popup']);
  setVariable('--img-box-popup-checked', assets['img_img-box-popup-checked']);
  setVariable('--btn-grey', assets['img_btn-grey']);
  setVariable('--btn-yellow', assets['img_btn-yellow']);
  setVariable('--img-title-breach_door', assets['img_img-title-breach_door']);
  setVariable('--frame-1', assets['img_frame-1']);
  setVariable('--frame-2', assets['img_frame-2']);
  setVariable('--frame-3', assets['img_frame-3']);
  setVariable('--frame-4', assets['img_frame-4']);
  setVariable('--frame-5', assets['img_frame-5']);
  setVariable('--frame-1-selected', assets['img_frame-1-selected']);
  setVariable('--frame-2-selected', assets['img_frame-2-selected']);
  setVariable('--frame-3-selected', assets['img_frame-3-selected']);
  setVariable('--frame-4-selected', assets['img_frame-4-selected']);
  setVariable('--frame-5-selected', assets['img_frame-5-selected']);
  setVariable('--img-grenade-1', assets['img_img-grenade-1']);
  setVariable('--img-grenade-2', assets['img_img-grenade-2']);
  setVariable('--img-grenade-3', assets['img_img-grenade-3']);
  setVariable('--img-grenade-4', assets['img_img-grenade-4']);
  setVariable('--img-grenade-5', assets['img_img-grenade-5']);
  setVariable('--box-grey', assets['rarity_common']);
  setVariable('--box-green', assets['rarity_uncommon']);
  setVariable('--box-rare', assets['rarity_rare']);
  setVariable('--box-epic', assets['rarity_epic']);
  setVariable('--box-legend', assets['rarity_legend']);
  setVariable('--box-gp-fx', assets['img_grandprize_fx']);
};

export const setAttributes = (lang) => {
  document.body.setAttribute('data-lang', lang);

  document.body.setAttribute('data-os', 'pc');

  if (BrowserInfo().iPhone || BrowserInfo().iPad || BrowserInfo().ios) {
    document.body.setAttribute('data-os', 'ios');
  }

  if (BrowserInfo().android) {
    document.body.setAttribute('data-os', 'android');
  }
};

export const groupingPrizes = (array, key) => {
  const newArray = [];
  const group = array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
  }, {});

  Object.keys(group).forEach((key, index) => {
    newArray.push({ ...group[key][0], total: group[key].length });
  });

  return newArray;
};

export const formatQty = (qty) => {
  return qty > 1 ? 'x' + qty : '';
};

export const sleep = (interval) => {
  return new Promise((resolve) => setTimeout(resolve, interval));
};

export const timeout = (fn, delay) => {
  let timer = null;
  timer = setTimeout(() => {
    fn();
    clearTimeout(timer);
    timer = null;
  }, delay);
};

export function throttle(key, fn, cooldown) {
  const nextTick = window.localStorage.getItem(key) || 0;
  if (Date.now() >= nextTick) {
    fn();
    const date = Date.now();
    const tick = date + cooldown;
    window.localStorage.setItem(key, tick);
  }
}

export function deleteThrottles(keys = []) {
  keys.forEach((key) => ls.removeItem(key));
}

export function isThrottled(key) {
  const lastTime = window.localStorage.getItem(key) || 0;
  if (Date.now() >= lastTime) return false;
  return true;
}

/**
 * Removing trailing param on url
 *
 */
export const removeParamUrl = () => {
  const location = window.location;
  window.history.pushState({}, document.title, location.origin + location.pathname);
};

/**
 * Get all param url
 */
export const getAllUrlParam = (url = window.location) => {
  let params = {};

  new URL(url).searchParams.forEach(function (val, key) {
    if (params[key] !== undefined) {
      if (!Array.isArray(params[key])) {
        params[key] = [params[key]];
      }
      params[key].push(val);
    } else {
      params[key] = val;
    }
  });

  return params;
};

/**
 * Get spesific param url
 *
 * @param {string} pa - Unique key of search string on url.
 */
export const getUrlParam = (pa) => {
  let url = window.location.href.replace(/#+.*$/),
    params = url.substring(url.indexOf('?') + 1, url.length).split('&'),
    param = {};

  for (let i = 0; i < params.length; i++) {
    let pos = params[i].indexOf('='),
      key = params[i].substring(0, pos),
      val = params[i].substring(pos + 1);

    param[key] = val;
  }

  return typeof param[pa] === 'undefined' ? false : param[pa];
};

/**
 *
 * @param {object} err - Error response object from axios.
 */
export const err_msg = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 500:
        return 'TOAST_ERROR';
      default:
        return err?.response?.data?.message || 'TOAST_ERROR';
    }
  } else if (err.request) {
    return 'TOAST_ERROR';
  } else {
    return err.code || 'TOAST_ERROR';
  }
};

/**
 *
 * @param {string} key - Unique key for storing and identifying your data in local storage.
 * @param {*} values - Value of data you want store in local storage.
 */
export const setLsObject = (key, values) => {
  window.localStorage.setItem(key, JSON.stringify(values));
};

/**
 *
 * @param {string} key - Unique key for accessing your stored data in local storage.
 * @returns {*} - Return any type of data you stored.
 */
export const getLsObject = (key) => {
  return JSON.parse(window.localStorage.getItem(key));
};

/**
 *
 * @param {object,array} data - list data that you want to preload
 * @param {string} prefix - prefix of image key from directus (e.g. img for img_bg)
 */
export const preload = (data, prefix = false) => {
  // prettier-ignore
  const allowedExtensions = ["jpg", "jpeg", "png", "mp3", "mp4", "webm", "mpg", "mpeg", "mov", "ico", "gif", "svg", "bmp", "otf", "ttf"];
  const audioExtensions = ['mp3', 'mp4', 'wav'];
  let mapping = prefix ? Object.fromEntries(Object.entries(data).filter(([key]) => key.includes(prefix))) : data;
  let audios = [];
  let images = [];

  for (let i in mapping) {
    if (mapping.hasOwnProperty(i)) {
      let fileExtension = mapping[i].split('.').pop();
      if (allowedExtensions.includes(fileExtension)) {
        if (audioExtensions.includes(fileExtension)) {
          audios[i] = new Audio();
          audios[i].src = mapping[i];
        } else {
          images[i] = new Image();
          images[i].src = mapping[i];
        }
      }
    }
  }
};

/**
 *
 * @param {component} lazyComponent - React component that you want to code splitting
 * @param {number} attemptsLeft - How many attemps to load the chunks if failed
 */
export const componentLoader = (lazyComponent, attemptsLeft = 3) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
  });
};
