import React, { useRef } from 'react';
import './index.scss';
import Tippy from '@tippyjs/react';

const Tooltip = ({ content, disabled, children, placement = 'top', timer = 2000, offset = [0, 0] }) => {
  const timerRef = useRef();

  return (
    <Tippy
      zIndex={9999}
      className="tooltip"
      content={content}
      disabled={disabled}
      placement={placement}
      offset={offset}
      trigger="click"
      hideOnClick={true}
      onShow={(instance) => {
        if (!timer) return;
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          instance.hide();
        }, timer);
      }}
      onHide={() => {
        clearTimeout(timerRef.current);
      }}>
      {children}
    </Tippy>
  );
};

export default Tooltip;
