import React from 'react';
import './index.scss';

import { useAppSelector } from 'redux/hooks';

import { Number } from 'components';

interface Props {
  className?: string;
  type: 'token' | 'cp';
  animationDuration?: number;
}

const Currency = ({ className, type, animationDuration = 0.5 }: Props) => {
  const checkAmount = (): number => {
    if (type === 'cp') return info.cp;
    if (type === 'token') return info.tokens;
    return 0;
  };

  const { info } = useAppSelector((state) => state.main);
  const amount = checkAmount();

  return (
    <div className={'currency ' + className}>
      <div className={`ico-${type}`} />
      <span>
        <Number end={amount} duration={animationDuration} />
      </span>
    </div>
  );
};

export default Currency;
